import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Third Party Logistics</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Third Party Logistics</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/third.webp" />
                <h2>Third Party Logistics</h2>
                <p>
                  {companyname} stands at the forefront of the industry as the
                  premier third-party logistics services provider, specializing
                  in delivering comprehensive and seamless supply chain
                  solutions. In today's dynamic and fiercely competitive
                  business landscape, the surge in demand for 3PL services is
                  unmistakably evident. The modern business landscape
                  understands the intrinsic value of entrusting their supply
                  chain requisites to seasoned experts, thereby elevating
                  customer satisfaction and bolstering profitability. In
                  response to this paradigm shift, {companyname} steps forward
                  to furnish innovative and strategic logistics solutions to a
                  diverse array of industry verticals.
                  <br />
                  <br />
                  Our commitment finds resonance in our comprehensive suite of
                  3PL services, meticulously designed to encapsulate the full
                  spectrum of supply chain demands. From precision-driven
                  inventory management and adept storage solutions to seamless
                  transportation orchestration and flawless order fulfillment,
                  we bring forth an array of offerings that not only streamline
                  operations but also enhance efficiency and trim costs. Our
                  services are underpinned by a dedication to
                  cost-effectiveness, tailored customization, and an unwavering
                  focus on placing the customer at the core of our endeavors.
                  <br />
                  <br />
                  The power of our extensive network, spanning both
                  transportation and warehousing, empowers businesses to expand
                  their horizons, tapping into new markets and opportunities
                  with confidence. A technological backbone fortified with
                  cutting-edge innovations ensures that end-to-end visibility
                  and transparency shine through every facet of your supply
                  chain operations. At {companyname}, our lodestar is a
                  relentless pursuit of excellence and a tireless drive for
                  innovation, attributes that define our commitment to elevating
                  your operations to the zenith of success.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
