import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>About Us</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>About Us</Link>
            </div>
          </div>
        </div>
      </div>
      {/* //bottom-grids*/}
      {/*/about-section */}
        {/* About Start */}
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/about.webp" alt="Image" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <p>Welcome to {companyname}</p>
                  <h2>Delivering Beyond Boundaries.</h2>
                </div>
                <div className="about-text">
                  <p>
                    {companyname} has stood at the forefront as a prominent
                    provider of freight and trading services. Over time, we have
                    embraced the challenges that are intrinsic to our clients'
                    most intricate quandaries, consistently delivering inventive
                    and ingenious solutions. The amalgamation of our expertise,
                    skilled workforce, cutting-edge technology, and the
                    cumulative experience of our parent company culminate to
                    form an unbeatable alliance. Our genesis is rooted in
                    resolving complexities for our own entities, which is why we
                    excel in expertly managing the burdens that may weigh upon
                    you.
                    <br />
                    <br />
                    Our approach traverses beyond mere service provision; it is
                    a dynamic partnership, a collaborative endeavor underpinned
                    by a lucid and mutual comprehension of your objectives. As
                    we march forward, your triumph becomes inextricably linked
                    with ours.
                  </p>
               
                </div>
              </div>
            </div>
            <p>
            Our diligence transcends the superficial; it's an immersive process that delves deep into comprehending your business, the intricacies of the challenges you grapple with, and the outcomes that hold paramount significance to you. Our tenacity lies in decoding the nuances of your operations, in sculpting solutions that resonate with your unique requirements, and in championing your objectives as our own. The tapestry we weave isn't just about transactions; it's a symphony of shared aspirations, a testament to our commitment to stand steadfastly by your side, navigating the realm of business intricacies to deliver solutions that propel your success forward.
            </p>
          </div>
        </div>
        {/* About End */}

        <div className="feature wow fadeInUp" data-wow-delay="0.1s">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i
                      className="fa fa-6x fa-bullseye"
                      style={{ color: "#fdbe33" }}
                    />
                  </div>
                  <div className="feature-text">
                    <h3>Our Mission</h3>
                    <p>
                      Emerge as renowned trailblazers in the realm of global
                      forwarding and logistics, attaining worldwide recognition
                      by consistently surpassing our clients' expectations and
                      addressing their diverse needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i
                      className="fa fa-6x fa-eye"
                      style={{ color: "#05597c" }}
                    />
                  </div>
                  <div className="feature-text">
                    <h3>Our Vision</h3>
                    <p>
                      To ensure we meet our clients' needs and surpass their
                      expectations, we recognize the imperative of continuous
                      evolution and staying well-versed in the ever-changing
                      landscape of our industry's emerging trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <Footer />
    </>
  );
}
