import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Road Freight</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Road Freight</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/road.jpg" />
                <h2>Road Freight</h2>
                <p>
                  In addition to our robust air and ocean freight services,{" "}
                  {companyname} takes great pride in extending our expertise to
                  encompass the crucial realm of land freight trucking services.
                  Recognizing the pivotal role that time-definite trucking plays
                  in a comprehensive logistics strategy, we have meticulously
                  designed a framework that harmonizes seamlessly with our other
                  offerings. At {companyname}, we understand that the final leg
                  of a shipment's journey is just as essential as its initial
                  stages, and our commitment to excellence transcends modal
                  boundaries.
                  <br />
                  <br />
                  Embedded within the core of our operational philosophy is an
                  unwavering dedication to upholding the highest performance
                  standards in the industry. This commitment to excellence isn't
                  just a lofty ideal; it's the bedrock upon which we build our
                  services. Our vast and interconnected network of partners
                  spans the globe, ensuring that your cargo is in capable hands
                  at every stage of its journey. However, our diligence doesn't
                  stop at establishing partnerships; we subject our suppliers
                  and collaborators to rigorous vetting processes. By evaluating
                  criteria such as historical performance, incidents of damages,
                  and on-time delivery history, we guarantee that only the most
                  reliable and efficient partners are entrusted with your
                  shipments.
                  <br />
                  <br />
                  What sets {companyname} apart is our holistic approach to
                  logistics, where each facet of transportation, be it air,
                  ocean, or land, seamlessly integrates into a unified whole.
                  This integration isn't just a convenience; it's a strategic
                  advantage. Your cargo doesn't navigate through isolated
                  segments; it traverses a meticulously planned and executed
                  path, guided by a singular commitment to precision and
                  punctuality. Our mission is clear: to provide you with a
                  comprehensive logistics solution that ensures your cargo's
                  safe and timely arrival, every time. When you choose{" "}
                  {companyname}, you're choosing a partner that not only meets
                  your needs but consistently exceeds your expectations, no
                  matter the mode of transport.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
