import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>

        {/* Top Bar Start */}
        <div className="top-bar">
          <div className="container-fluid">
            <div className="row align-items-center">
             
              <div className="col-lg-4 col-md-7 d-none d-lg-block">
                <div className="row">
                
                  <div className="col-">
                    <div className="top-bar-item">
                      <div className="top-bar-icon">
                        <i className="flaticon-call" />
                      </div>
                      <div className="top-bar-text text-center">
                        <h3>Call Us</h3>
                        <p>{companynumber}</p>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="logo text-center">
                  <Link to="/Home">
                   
                    <img src="img/logo.png" style={{height:"160px"}} alt="Logo"/>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-7 d-none d-lg-block">
                  <div className="col-">
                    <div className="top-bar-item">
                      <div className="top-bar-icon">
                        <i className="flaticon-send-mail" />
                      </div>
                      <div className="top-bar-text text-center">
                        <h3>Email Us</h3>
                        <p>{companyemail}</p>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Top Bar End */}
        {/* Nav Bar Start */}
        <div className="nav-bar">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
              <Link to="#" className="navbar-brand">MENU</Link>
              <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                <div className="navbar-nav mr-auto">
                  <Link to="/Home" className="nav-item nav-link">Home</Link>
                  <Link to="/About" className="nav-item nav-link">Who We Are</Link>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Freight Solutions</Link>
                    <div className="dropdown-menu">
                    <Link to="/Road " className="dropdown-item">Road Freight</Link>
                      <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                      <Link to="/Air " className="dropdown-item">Air Freight</Link>
                      <Link to="/Warehouse " className="dropdown-item">Warehouse</Link>
                      <Link to="/Contract" className="dropdown-item">Contract Logistics</Link>
                      <Link to="/Third " className="dropdown-item">Third Party Logistics</Link>
                    </div>
                  </div>
                  <div className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Trading Solutions</Link>
                    <div className="dropdown-menu">
                      <Link to="/Trading " className="dropdown-item">Apparel & Lifestyle Products</Link>
                      <Link to="/Trading" className="dropdown-item">Cosmetics & Skincare</Link>
                      <Link to="/Trading " className="dropdown-item">Food & Beverages</Link>
                      <Link to="/Trading " className="dropdown-item">Pharma & HealthCare</Link>
                      <Link to="/Trading" className="dropdown-item">Fitness & Sports</Link>
                      <Link to="/Trading " className="dropdown-item">Retail, Fmcg & E-commerce</Link>
                    </div>
                  </div>
                
                  <Link to="/Contact" className="nav-item nav-link">Contact Us</Link>
                </div>
                <div className="ml-auto">
                  <Link className="btn" to="/Getquote">Request A Quote</Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
        {/* Nav Bar End */}
 

      <Outlet />
    </>
  );
};

export default Header;
