import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Warehouse</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Warehouse</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/warehouse.jpg" />
                <h2>Warehouse</h2>
                <p>
                  Within {companyname}, we boast a reservoir of experience,
                  finely honed over time, dedicated to delivering superlative
                  warehouse services that ensure the safety and security of your
                  valuable products. Our comprehensive approach spans the gamut
                  from adept storage solutions to seamless distribution
                  strategies, covering every facet of your supply chain
                  management endeavors. As a trailblazing force among
                  warehousing companies in India, we take immense pride in our
                  capacity to provide economical, unwavering, and resilient
                  warehouse logistics solutions, meticulously tailored to
                  accommodate the diverse requisites of every industry.
                  <br />
                  <br />
                  The cornerstone of our service repertoire is a commitment to
                  adaptability, an ethos that empowers us to cater to a broad
                  spectrum of needs. Whether your demands encompass a
                  specialized space for prolonged durations or a communal area
                  for seasonal storage surges, we stand poised to assist.
                  Equipped with a nationwide grid of warehouses, bolstered by
                  cutting-edge storage facilities, and fortified by robust
                  technological systems, we extend to you a spectrum of
                  world-class warehouse services.
                  <br />
                  <br />
                  Our dedication transcends the mere physical realm; it's an
                  embodiment of trust and reliability. With {companyname},
                  you're not just partnering with a service provider – you're
                  aligning with a promise, a commitment to safeguarding your
                  products and optimizing their journey. As a beacon of
                  excellence in the domain of warehouse logistics, we ensure
                  that your goods are meticulously stored, efficiently managed,
                  and seamlessly distributed, fostering an atmosphere of
                  assurance and empowerment in every step of your supply chain.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
