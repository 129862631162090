import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />

        <div id="carousel" className="carousel slide  " data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#carousel" data-slide-to={0} className="active" />
            <li data-target="#carousel" data-slide-to={1} />
            <li data-target="#carousel" data-slide-to={2} />
          </ol>
          <div className="carousel-inner w3-banner jarallax">
            <video
              className="img-fluid"
              autoPlay
              muted
              loop
              style={{ zIndex: "1" }}
            >
              <source src="img/video.mp4" type="video/mp4" />
            </video>
            <div className="carousel-item active">
              <div className="carousel-caption">
                <p className="animated fadeInRight">We Are {companyname}</p>
                <h1 className="animated fadeInLeft">
                  {" "}
                  Beyond Transport, We Deliver Solutions
                </h1>
                <Link className="btn animated fadeInUp" to="/Getquote">
                  Get Quote
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption">
                <p className="animated fadeInRight">Who We Are</p>
                <h1 className="animated fadeInLeft">
                  Your Cargo, Our Priority
                </h1>
                <Link className="btn animated fadeInUp" to="/About">
                  Learn More
                </Link>
              </div>
            </div>
            <div className="carousel-item">
              <div className="carousel-caption">
                <p className="animated fadeInRight">What We Do</p>
                <h1 className="animated fadeInLeft">
                  Provide seamles Freight and Trading Services
                </h1>
                <Link className="btn animated fadeInUp" to="/Services">
                  Explore Services
                </Link>
              </div>
            </div>
          </div>
          <Link
            className="carousel-control-prev"
            to="#carousel"
            role="button"
            data-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </Link>
          <Link
            className="carousel-control-next"
            to="#carousel"
            role="button"
            data-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </Link>
        </div>
        {/* Carousel End */}
        {/* Feature Start*/}
        <div className="feature wow fadeInUp" data-wow-delay="0.1s">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i
                      className="fa fa-6x fa-bullseye"
                      style={{ color: "#fdbe33" }}
                    />
                  </div>
                  <div className="feature-text">
                    <h3>Our Mission</h3>
                    <p>
                      Emerge as renowned trailblazers in the realm of global
                      forwarding and logistics, attaining worldwide recognition
                      by consistently surpassing our clients' expectations and
                      addressing their diverse needs.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="feature-item">
                  <div className="feature-icon">
                    <i
                      className="fa fa-6x fa-eye"
                      style={{ color: "#05597c" }}
                    />
                  </div>
                  <div className="feature-text">
                    <h3>Our Vision</h3>
                    <p>
                      To ensure we meet our clients' needs and surpass their
                      expectations, we recognize the imperative of continuous
                      evolution and staying well-versed in the ever-changing
                      landscape of our industry's emerging trends.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Feature End*/}
        {/* About Start */}
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/about.webp" alt="Image" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <p>Welcome to {companyname}</p>
                  <h2>Delivering Beyond Boundaries.</h2>
                </div>
                <div className="about-text">
                  <p>
                    {companyname} has stood at the forefront as a prominent
                    provider of freight and trading services. Over time, we have
                    embraced the challenges that are intrinsic to our clients'
                    most intricate quandaries, consistently delivering inventive
                    and ingenious solutions. The amalgamation of our expertise,
                    skilled workforce, cutting-edge technology, and the
                    cumulative experience of our parent company culminate to
                    form an unbeatable alliance. Our genesis is rooted in
                    resolving complexities for our own entities, which is why we
                    excel in expertly managing the burdens that may weigh upon
                    you.
                    <br />
                    <br />
                    Our approach traverses beyond mere service provision; it is
                    a dynamic partnership, a collaborative endeavor underpinned
                    by a lucid and mutual comprehension of your objectives. As
                    we march forward, your triumph becomes inextricably linked
                    with ours.
                  </p>
                  <Link className="btn" to="/About">
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}

        {/* Service Start */}
        <div className="service">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Freight Solutions</p>
              <h2>Freight Solutions We Provide</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/road.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> In addition to our robust air and ocean freight services,{" "}
                  {companyname} takes great pride in extending our expertise to
                  encompass the crucial realm of land freight trucking services...</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Road Freight</h3>
                    <Link className="btn" to="/Road" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/sea.png"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p>  Whether your shipment constitutes a comprehensive full
                  container load (FCL) or a more streamlined less than container
                  load (LCL), rest assured that we are primed to align with your
                  overseas shipping timelines, courtesy of our numerous sailings
                  every week....</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Sea Freight</h3>
                    <Link className="btn" to="/Sea" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/air.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> {companyname} stands as your dependable partner in delivering
                  comprehensive import and export door-to-door air freight
                  transportation solutions. Our services encompass an array of
                  considerations, whether it's optimizing costs or accelerating
                  delivery.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Air Freight</h3>
                    <Link className="btn" to="/Air" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/warehouse.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> Within {companyname}, we boast a reservoir of experience,
                  finely honed over time, dedicated to delivering superlative
                  warehouse services that ensure the safety and security of your
                  valuable products.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Warehouse</h3>
                    <Link
                      className="btn"
                      to="/Warehouse"
                      data-lightbox="service"
                    >
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/contract.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p>  As a trailblazer in the realm of contract logistics,{" "}
                  {companyname} stands distinguished by its extensive
                  decade-long experience and profound expertise in the industry.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Contract Logistics</h3>
                    <Link
                      className="btn"
                      to="/Contract"
                      data-lightbox="service"
                    >
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/third.webp"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> {companyname} stands at the forefront of the industry as the
                  premier third-party logistics services provider, specializing
                  in delivering comprehensive and seamless supply chain
                  solutions.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Third Party Logistics</h3>
                    <Link className="btn" to="/Third" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="team">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Trading Solutions</p>
              <h2>Explore Our Trading Solutions</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/apparel.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Apparel & Lifestyle Products</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/cosmetics.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Cosmetics & Skincare</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/food.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Food & Beverages</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/pharma.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Pharma & HealthCare</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/fitness.png"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Fitness & Sports</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/retail.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Retail, Fmcg & E-commerce</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <Footer />
    </>
  );
}
