import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Trading Solutions</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Trading Solutions</Link>
            </div>
          </div>
        </div>
      </div>
      {/* //bottom-grids*/}
      {/*/about-section */}
        {/* About Start */}
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/apparel.jpg" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Apparel & Lifestyle Products</h3>
                </div>
                <div className="about-text">
                  <p>
                  {companyname} stands as a beacon of excellence in delivering meticulously optimized warehouse and logistics services tailored specifically for the realms of Apparel, Fashion, Uniforms, Lifestyle products, and the intricate web of associated e-commerce and retail distribution. Our offerings shine as exemplars within the industry's landscape of technology-driven and digitally-infused solutions. In an era where the digital frontier commands paramount significance, our services rise to the occasion, propelled by cutting-edge technology and a forward-looking approach.
<br/><br/>
In the vibrant realm of apparel and lifestyle, the landscape of Internet sales on diverse marketplaces takes center stage. Recognizing this, we elevate the priority of streamlining the online retail experience for brands, a strategic maneuver in the face of the anticipated exponential growth of online retail in India in the imminent years.
                  </p>
               
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
             
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Cosmetics & Skincare</h3>
                </div>
                <div className="about-text">
                  <p>
                  The allure of a meticulously crafted, temperature-sensitive and controlled supply chain finds its exquisite embodiment through {companyname}, catering specifically to the unique needs of makeup and skincare companies. Within this realm, we meticulously choreograph a multi-faceted logistics routine, deftly managing the intricate tapestry of retailer requisites and the relentless surge of e-commerce demands.
<br/>
Our approach extends beyond the ordinary; it's a manifestation of foresight, a meticulous fusion of strategic strategies and unwavering precision. As we embark on this journey, our compass is calibrated to the demanding priorities of an ever-evolving cosmetics and skincare industry. Time and again, we ascend the podium of flawless planning and execute on-time deliveries, a testament to our commitment to harmonizing with the dynamic cadence of this vibrant sector.

                  </p>
               
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/cosmetics.jpg" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/food.jpg" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Food & Beverages</h3>
                </div>
                <div className="about-text">
                  <p>
                    
{companyname} proudly unveils its formidable Contract Logistics model, meticulously crafted to serve as a potent antidote to the myriad challenges that reverberate across the Food and Beverage industry. This dynamic framework stands as a resolute response to the multifaceted conundrums that characterize this thriving sector, ranging from the labyrinthine fluctuations in commodity prices to the intricate web of manufacturing constraints. As the industry navigates the shifting tides of consumer preferences, with a pronounced leaning toward organic products, we stand as vanguards in orchestrating a logistical symphony that seamlessly aligns with these evolving trends.
<br/>
Within this intricate ecosystem, where hygiene, traceability, and the relentless march of time hold paramount significance, our Contract Logistics model emerges as a bastion of support. With each meticulous step, we endeavor to alleviate the weight of these challenges, lending our expertise to unfurl solutions that resonate with the unique demands of the Food and Beverage industry.
                  </p>
               
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
             
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Pharma & HealthCare</h3>
                </div>
                <div className="about-text">
                  <p>
                  {companyname} takes immense pride in its Pharma & Healthcare services portfolio, an embodiment of our unwavering commitment to the well-being of individuals reliant on pharmaceutical products, medicines, herbal remedies, and medical equipment. Our ethos revolves around a profound sense of responsibility, recognizing the pivotal role these products play in safeguarding lives.<br/>
                  Within this realm, our devotion finds expression through a comprehensive tapestry of measures designed to uphold sanitation, safety, and stringent quality standards. Temperature control within our warehouses forms an essential pillar, ensuring the stable environment vital for preserving the efficacy of sensitive healthcare items. A relentless pursuit of avoiding shortages underpins our operations, guaranteeing that critical supplies remain steadfastly available.                  </p>
               
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/pharma.jpg" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/fitness.png" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Fitness & Sports</h3>
                </div>
                <div className="about-text">
                  <p>
                  {companyname} proudly claims its stature as the premier freight forwarder, seamlessly facilitating the import and export of freight to and from India. Our prowess is fortified by an expansive network of warehouses and distribution hubs meticulously woven across the Indian landscape, complemented by a comprehensive array of international air freight, ocean cargo transportation, and on-carriage trucking services.
<br/>
Our approach is rooted in synergy, a harmonious blend of domestic freight services, Less-Than-Truckload (LTL) freight solutions, and domestic air cargo operations. This orchestration ensures a seamless flow, as goods gracefully traverse from warehouses to airports, docks, consolidation hubs, or dispatch centers, spanning the expanse of India.
                  </p>
               
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="about wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row align-items-center">
             
              <div className="col-lg-7 col-md-6">
                <div className="section-header text-left">
                  <h3>Retail, Fmcg & E-commerce</h3>
                </div>
                <div className="about-text">
                  <p>
                  Embracing the dynamic needs of the retail and e-commerce sectors, {companyname} unfurls a comprehensive tapestry of multimode transport services. Designed to cater to the ever-evolving demands of the modern landscape, our offerings extend a responsive hand to requirements ranging from swift one-day deliveries to express consignments.
<br/>
As a testimony to our commitment to seamless logistics, we extend our expertise to encompass a diverse array of Fast-Moving Consumer Goods (FMCG) logistics solutions. Anchored by an expansive network of adept professionals, bolstered by cutting-edge decision support systems, and fortified by in-house technological prowess, we stand poised to sculpt solutions that not only address the intricate demands of FMCG logistics but also elevate them to new pinnacles of efficiency.
                  </p>
               
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="about-img">
                  <img src="img/retail.jpg" style={{height:"300px"}} alt="Image" />
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* About End */}



      <Footer />
    </>
  );
}
