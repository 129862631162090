import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Air Freight</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Air Freight</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/air.jpg" />
                <h2>Air Freight</h2>
                <p>
                  {companyname} stands as your dependable partner in delivering
                  comprehensive import and export door-to-door air freight
                  transportation solutions. Our services encompass an array of
                  considerations, whether it's optimizing costs or accelerating
                  delivery. We orchestrate both consolidated and direct air
                  services, finely tuned to your specific priorities. With an
                  adept team at the helm, we seamlessly navigate your goods to
                  and from virtually any corner of the globe.
                  <br />
                  In a world where supply chains have evolved into critical
                  lifelines and rapid transit times are non-negotiable, we
                  leverage the expertise of premium carriers sporting
                  established routes for our consolidations. Our approach isn't
                  limited to a singular trajectory; rather, we present a vast
                  spectrum of choices, ensuring that your air freight forwarding
                  needs are met with the utmost competitiveness and reliability.
                  <br />
                  Beyond the confines of traditional services, we empower our
                  clients to tap into the strength of our robust international
                  network of offices and affiliates. This interwoven web enables
                  us to facilitate door-to-door deliveries, seamlessly
                  navigating the intricacies of clearance procedures and
                  ensuring the secure delivery of your cargo to its final
                  destination. At {companyname}, our commitment is resolute – to
                  provide you with a holistic and hassle-free air freight
                  experience, ensuring that your cargo reaches its endpoint with
                  efficiency, precision, and the utmost care.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
