import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Sea Freight</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Sea Freight</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/sea.png" />
                <h2>Sea Freight</h2>
                <p>
                  Whether your shipment constitutes a comprehensive full
                  container load (FCL) or a more streamlined less than container
                  load (LCL), rest assured that we are primed to align with your
                  overseas shipping timelines, courtesy of our numerous sailings
                  every week.
                  <br />
                  The specialized arm of our ocean freight division orchestrates
                  the intricate choreography of logistical maneuvers, ensuring
                  the seamless transit of full container load (FCL) cargo to and
                  from international ports around the globe. Our proficiency
                  extends to providing tailored solutions for both FCL and LCL
                  shipments, fostering a dynamic interchange between major ports
                  worldwide.
                  <br />
                  Our capabilities extend beyond the boundaries of mere cargo
                  movement. Whether you're dealing with a singular shipment, a
                  multifaceted series of shipments, or even intricate project
                  cargo, our adept team stands ready to fulfill all your
                  shipping requisites. Through a culmination of expertise,
                  industry acumen, and an unwavering commitment to customer
                  satisfaction, we orchestrate a symphony of services that cater
                  to your unique demands. With each shipment, our promise
                  remains unaltered – to exceed your expectations, navigating
                  the complex sea of logistics with an ease that mirrors the
                  calmest of waters.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
