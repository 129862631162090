import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <div>
        {/* Page Header Start */}
        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2>Contact Us</h2>
              </div>
              <div className="col-12">
              <Link to="/Home">Home</Link>
                <Link to>Contact Us</Link>
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* Contact Start */}
        <div className="contact wow fadeInUp">
          <div className="container">
            <div className="section-header text-center">
              <p>Get In Touch</p>
              <h2>For Any Query</h2>
            </div>
            <div className="row">
              <div className="col-md-6 contact-inf">
                <div className="contact-info">
                  <div className="contact-item">
                    <i className="flaticon-address" />
                    <div className="contact-text">
                      <h2>Location</h2>
                      <p>{companyaddress}</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <i className="flaticon-call" />
                    <div className="contact-text">
                      <h2>Phone</h2>
                      <p>{companynumber}</p>
                    </div>
                  </div>
                  <div className="contact-item">
                    <i className="flaticon-send-mail" />
                    <div className="contact-text">
                      <h2>Email</h2>
                      <p>{companyemail}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-form">
                  <div id="success" />
                  <form
                    action="/php/thankyou-contact.php "
                    method="post"
                    onSubmit={(event) => handleSubmit(event)}
                  >
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            onChange={(event) => handleFnameChange(event)}
                            type="text"
                            className
                            id="fname"
                            name="fname"
                            value={fname}
                            placeholder="Enter your first name"
                            style={{
                              width: "100%",
                              padding: "10px",
                              margin: "6px 0",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            onChange={(event) => handlelnameChange(event)}
                            type="text"
                            style={{
                              width: "100%",
                              padding: "10px",
                              margin: "6px 0",
                            }}
                            id="lname"
                            name="lname"
                            value={lname}
                            placeholder="Enter your last name"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="control-group">
                          <input
                            onChange={(event) => handleemailChange(event)}
                            type="email"
                            style={{
                              width: "100%",
                              padding: "10px",
                              margin: "6px 0",
                            }}
                            id="email"
                            name="email"
                            value={email}
                            placeholder="Enter your Email"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="control-group">
                          <input
                            onChange={(event) => handlesubjectChange(event)}
                            type="text"
                            style={{
                              width: "100%",
                              padding: "10px",
                              margin: "6px 0",
                            }}
                            id="subject"
                            name="subject"
                            value={subject}
                            placeholder="Enter subject"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="control-group">
                          <textarea
                            style={{
                              width: "100%",
                              padding: "10px",
                              margin: "6px 0",
                            }}
                            id="message"
                            name="message"
                            rows={1}
                            placeholder="Enter Message"
                            defaultValue={""}
                            value={message}
                            onChange={(event) => handlemessageChange(event)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          name="submit"
                          className="btn btn-primary w-100 py-3"
                          type="submit"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  <h1>{result}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts-sub-5">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.888805760507!2d73.01924262520411!3d19.02462083216943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c3beafe54bab%3A0xe5c983a0d946cbbf!2sHaware%20Mall!5e0!3m2!1sen!2sin!4v1691401384487!5m2!1sen!2sin"
            width="100%"
            height="450"

            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>{" "}
        </div>
        {/* Contact End */}
      </div>

      <Footer />
    </>
  );
}
