import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Contract Logistics</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Contract Logistics</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-content wow fadeInUp">
                <img src="img/contract.jpg" />
                <h2>Contract Logistics</h2>
                <p>
                  As a trailblazer in the realm of contract logistics,{" "}
                  {companyname} stands distinguished by its extensive
                  decade-long experience and profound expertise in the industry.
                  We present a harmonized suite of integrated services that
                  traverse the entire spectrum of supply chain operations,
                  ushering businesses toward streamlined and efficient processes
                  from inception to culmination. With a firm grasp of industry
                  intricacies, we embark on collaborative journeys with our
                  clients, furnishing a comprehensive array of offerings,
                  including inventory management, storage and distribution,
                  route optimization, order fulfillment, supply chain
                  consultancy, and a myriad of other solutions.
                  <br />
                  <br />
                  Our modus operandi revolves around customization, tailoring
                  our services to precisely match the unique contours of each
                  client's requisites. Be it localized operations confined to a
                  specific geographical region or expansive undertakings that
                  span multiple cities, our adaptability knows no bounds. A
                  potent recipe of meticulous attention to detail, a sprawling
                  nation-wide network, cutting-edge technology, and strategic
                  foresight forms the crux of our approach, all meticulously
                  orchestrated to drive the triumphant execution of your
                  business endeavors.
                  <br />
                  <br />
                  From the inception of our client engagement through the
                  intricacies of project execution and beyond, the bedrock of
                  our ethos rests on an unwavering dedication to service
                  excellence. With {companyname}, you're not just availing
                  services; you're partnering with an organization that treats
                  your success as its own. Our commitment isn't fleeting; it's
                  an enduring pledge to ensure customer satisfaction, to propel
                  your business aspirations, and to stand as a steadfast pillar
                  of support throughout your journey toward operational
                  brilliance.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar">
                <div className="sidebar-widget wow fadeInUp">
                  <h2 className="widget-title">Other Freight Services</h2>
                  <div className="category-widget">
                    <ul>
                      <li>
                        <Link to="/Road ">Road Freight</Link>
                      </li>
                      <li>
                        <Link to="/Sea">Sea Freight</Link>
                      </li>
                      <li>
                        {" "}
                        <Link to="/Air ">Air Freight</Link>
                      </li>
                      <li>
                        <Link to="/Warehouse ">Warehouse</Link>
                      </li>
                      <li>
                        <Link to="/Contract">Contract Logistics</Link>
                      </li>
                      <li>
                        <Link to="/Third ">Third Party Logistics</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
