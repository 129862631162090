import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>


      <div className="footer wow fadeIn" data-wow-delay="0.3s">
          <div className="container">
            <div className="row">
            
              
              <div className="col-md-6 col-lg-3">
                <div className="newsletter">
                  <h2>{companyname}</h2>
                  <p>
                  {companyname} has stood at the forefront as a prominent
            provider of freight and trading services. Over time, we have
            embraced the challenges that are intrinsic to our clients'
            most intricate quandaries, consistently delivering inventive
            and ingenious solutions. 
                  </p>
                  <div className="form tex-center" style={{position:'relative', }}>
                    <Link to="/Contact" className="btn">Contact Us</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="footer-link">
                  <h2>Freight Solutions </h2>
                  <Link to="/Road ">Road Freight</Link>
                      <Link to="/Sea">Sea Freight</Link>
                      <Link to="/Air ">Air Freight</Link>
                      <Link to="/Warehouse ">Warehouse</Link>
                      <Link to="/Contract">Contract Logistics</Link>
                      <Link to="/Third ">Third Party Logistics</Link>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="footer-link">
                  <h2>Useful Links</h2>
                  <Link to="/Home" >Home</Link>
                  <Link to="/About" >About</Link>
                  <Link to="/Services" >Freight Services</Link>
                  <Link to="/Trading" >Trading Services</Link>
                  <Link to="/Contact" >Contact</Link>
                  <Link to="/Getquote" >Getquote</Link>

              
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="footer-contact">
                  <h2>Office Contact</h2>
                  <p><i className="fa fa-map-marker-alt" />{companyaddress}</p>
                  <p><i className="fa fa-phone-alt" />{companynumber}</p>
                  <p><i className="fa fa-envelope" />{companyemail}</p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="container footer-menu">
            <div className="f-menu">
              <Link to="/Terms">Terms of use</Link>
              <Link to="/Privacy">Privacy policy</Link>
           
            </div>
          </div>
          <div className="container copyright">
            <div className="row">
              <div className="col-md-6">
                <p>©2023 <Link to="/home">{companyname}</Link>, All Right Reserved.</p>
              </div>
              {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
              <div className="col-md-6">
              </div>
            </div>
          </div>
        </div>
        {/* Footer End */}
        <Link to="#" className="back-to-top"><i className="fa fa-chevron-up" /></Link>

    </>
  );
};

export default Footer;
