import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Freight and Trading Solutins</h2>
            </div>
            <div className="col-12">
              <Link to="/Home">Home</Link>
              <Link to>Freight and Trading Solutins</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="service">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Freight Solutions</p>
              <h2>Freight Solutions We Provide</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/road.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> In addition to our robust air and ocean freight services,{" "}
                  {companyname} takes great pride in extending our expertise to
                  encompass the crucial realm of land freight trucking services...</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Road Freight</h3>
                    <Link className="btn" to="/Road" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/sea.png"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p>  Whether your shipment constitutes a comprehensive full
                  container load (FCL) or a more streamlined less than container
                  load (LCL), rest assured that we are primed to align with your
                  overseas shipping timelines, courtesy of our numerous sailings
                  every week....</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Sea Freight</h3>
                    <Link className="btn" to="/Sea" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/air.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> {companyname} stands as your dependable partner in delivering
                  comprehensive import and export door-to-door air freight
                  transportation solutions. Our services encompass an array of
                  considerations, whether it's optimizing costs or accelerating
                  delivery.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Air Freight</h3>
                    <Link className="btn" to="/Air" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/warehouse.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> Within {companyname}, we boast a reservoir of experience,
                  finely honed over time, dedicated to delivering superlative
                  warehouse services that ensure the safety and security of your
                  valuable products.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Warehouse</h3>
                    <Link
                      className="btn"
                      to="/Warehouse"
                      data-lightbox="service"
                    >
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/contract.jpg"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p>  As a trailblazer in the realm of contract logistics,{" "}
                  {companyname} stands distinguished by its extensive
                  decade-long experience and profound expertise in the industry.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Contract Logistics</h3>
                    <Link
                      className="btn"
                      to="/Contract"
                      data-lightbox="service"
                    >
                      +
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="service-item">
                  <div className="service-img">
                    <img
                      src="img/third.webp"
                      alt="Image"
                      style={{ height: "260px" }}
                    />
                    <div className="service-overlay">
                      <p> {companyname} stands at the forefront of the industry as the
                  premier third-party logistics services provider, specializing
                  in delivering comprehensive and seamless supply chain
                  solutions.</p>
                    </div>
                  </div>
                  <div className="service-text">
                    <h3>Third Party Logistics</h3>
                    <Link className="btn" to="/Third" data-lightbox="service">
                      +
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="team">
          <div className="container">
            <div className="section-header text-center">
              <p>Our Trading Solutions</p>
              <h2>Explore Our Trading Solutions</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/apparel.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Apparel & Lifestyle Products</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/cosmetics.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Cosmetics & Skincare</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/food.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Food & Beverages</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/pharma.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Pharma & HealthCare</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/fitness.png"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Fitness & Sports</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item">
                  <div className="team-img">
                    <img
                      src="img/retail.jpg"
                      alt="Team Image"
                      style={{ height: "220px" }}
                    />
                  </div>
                  <div className="team-text">
                    <Link to="/Trading">
                      <h2>Retail, Fmcg & E-commerce</h2>
                    </Link>
                    <p>Trading Service</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      <Footer />
    </>
  );
}
